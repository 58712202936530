body {
  margin: 0;
}

.hero {
  position: relative;
  height: 95vh;
}

#hero-logo {
  width: 33vw;
  margin: auto;
}

.hero-content {
  position: absolute;
  top: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%;
  width: 100%;
}